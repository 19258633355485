import React, { useCallback, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const SearchFromList = (props) => {
  const { className, placeholder, list, propertiesKey, onClick } = props;
  const [isInactive, setIsInActive] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState([]);
  const inputChangeHandler = useCallback(
    (event) => {
      const value = event?.target?.value;
      if (value) {
        setIsInActive(false);
        setInputValue(value);
        setResults(
          list.filter((el) =>
            el[propertiesKey].toLowerCase().includes(value.toLowerCase())
          )
        );
      } else {
        setIsInActive(true);
      }
    },
    [list]
  );
  const highlight = (str) => {
    console.log(str);
    return str.replace(
      new RegExp(inputValue.replace("+", ""), "gi"),
      (match) => `<mark>${match}</mark>`
    );
  };
  return (
    <div className="dark:bg-gray-900 relative">
      <div className="relative mt-1">
        <div className="text-dark-gray absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <SearchIcon />
        </div>
        <input
          type="text"
          onBlur={() =>
            setTimeout(() => {
              setIsInActive(true);
            }, 200)
          }
          onChange={inputChangeHandler}
          className={`bg-gray-100 border-none focus:border-none focus:ring-0 focus:bg-gray-200 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white ${className}`}
          placeholder={placeholder}
        />
      </div>
      {!isInactive && (
        <ul className="z-10 py-2 text-sm max-h-64 overflow-y-auto vertical-scrollbar shadow-md absolute top-full mt-1 bg-white rounded-lg w-full min-h-[20px]">
          {results?.length > 0 ? (
            results?.map((item, index) => (
              <li
                onClick={() => {
                  onClick(item);
                  console.log(item);
                }}
                className="hover:bg-gray-100 pl-3 py-2 cursor-pointer"
                key={index}
                dangerouslySetInnerHTML={{
                  __html: highlight(item[propertiesKey]),
                }}
              ></li>
            ))
          ) : (
            <p className="text-center my-1">No item found</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchFromList;
