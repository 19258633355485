import React, { useEffect, useState, useContext, useRef } from "react";
import ChatItem from "./ChatItem";
import { ChatContext } from "../../store/chat-context";
import noChatFound from "../../assets/illustrations/no-chat.svg";
import chatBg from "../../assets/images/chat-bg.jpg";
import { ArrowLeftShort } from "react-bootstrap-icons";
import useFocus from "../../hooks/use-focus";
import { Link } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { gql, useMutation, useSubscription } from "@apollo/client";
import { Warning } from "@mui/icons-material";
import "../../index.css";
import Toast from "../UI/Toast";

const GET_MESSAGES = gql`
  subscription MyQuery($combined_id: String!) {
    users_messages(
      where: { combined_id: { _eq: $combined_id } }
      order_by: { created_at: asc }
    ) {
      message
      reciever_id
      sender_id
      seen
      id
      created_at
    }
  }
`;

const INSERT_MESSAGE = gql`
  mutation MyMutation(
    $object: users_messages_insert_input!
    $object_user_chats: users_user_chats_insert_input!
  ) {
    insert_users_messages_one(object: $object) {
      id
    }
    insert_users_user_chats_one(
      object: $object_user_chats
      on_conflict: {
        constraint: user_chats_combined_id_key
        update_columns: [last_message, sender_id, reciever_id, seen]
      }
    ) {
      id
    }
  }
`;

const MARK_AS_SEEN = gql`
  mutation MyMutation($combined_id: String!) {
    update_users_user_chats(
      where: { combined_id: { _eq: $combined_id } }
      _set: { seen: true }
    ) {
      affected_rows
    }
    update_users_messages(
      where: { combined_id: { _eq: $combined_id }, seen: { _eq: false } }
      _set: { seen: true }
    ) {
      affected_rows
    }
  }
`;
const Chats = (props) => {
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const { sidebarShown, setSidebarShown, setUserSelected } = props;
  const auth = useAuthUser();
  const user = auth();
  const ref = useRef();
  const { data } = useContext(ChatContext);
  const [inputRef, setInputFocus] = useFocus();
  const {
    data: fetchedMessages,
    loading: getMessagesLoading,
    error: getMessagesError,
  } = useSubscription(GET_MESSAGES, {
    variables: {
      combined_id: data.combined_id,
    },
  });
  console.log(fetchedMessages);
  
  const [addMessage] = useMutation(INSERT_MESSAGE);
  const [markAsSeen] = useMutation(MARK_AS_SEEN);
  console.log(messages);
  // send message
  useEffect(() => {
    setInputFocus();
  });
  useEffect(() => {
    if (getMessagesLoading) {
      setMessages([]);
    }
    if (fetchedMessages?.users_messages?.length) {
      setMessages(
        fetchedMessages.users_messages.map((message) => {
          return {
            ...message,
            isLoaded: true,
          };
        })
      );
      const lastMessage =
        fetchedMessages.users_messages[
          fetchedMessages.users_messages.length - 1
        ];
      if (lastMessage.reciever_id === user.id) {
        markAsSeen({ variables: { combined_id: data.combined_id } });
      }
    }
  }, [fetchedMessages, getMessagesLoading]);
  const handleSend = async (e) => {
    e.preventDefault();
    const message = inputRef.current.value;
    if (message === "") {
      return;
    }
    try {
      const object = {
        reciever_id: data.user.id,
        sender_id: user.id,
        message: message,
        combined_id: data.combined_id,
      };
      const object_user_chats = {
        reciever_id: data.user.id,
        sender_id: user.id,
        last_message: message,
        combined_id: data.combined_id,
      };
      console.log(object);
      console.log(object_user_chats);
      inputRef.current.value = "";
      setMessages([
        ...messages,
        { ...object, isLoaded: false, created_at: new Date() },
      ]);
      const response = await addMessage({
        variables: {
          object,
          object_user_chats,
        },
      });
      ref.current?.scrollIntoView({ behavior: "smooth" });
    } catch (err) {
      setError(err?.message);
    }
  };
  let lines = inputRef.current?.value?.split(/\r\n|\r|\n/).length || 1;
  console.log(lines);
  return (
    <div
      ref={ref}
      className={`message overflow-y-auto vertical-scrollbar relative h-full max-h-full w-full ${
        sidebarShown ? "hidden" : "flex"
      } flex-col justify-between ${props.className}`}
      style={{
        backgroundImage: `url(${chatBg})`,
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
      }}>
      {/* Top */}
      <div
        className="message-header sticky top-0 p-2 w-full flex text-gray-600 bg-opacity-10 bg-gray-300"
        style={{ backdropFilter: `blur(5px)` }}>
        {/* Back button */}
        <button
          onClick={() => {
            setSidebarShown(true);
            setUserSelected(false);
            props.setUser(null);
          }}
          className="sm:hidden mr-2">
          <ArrowLeftShort className="w-6 h-6" />
        </button>
        <Link
          to={`/user-profile/${data.user?.id}`}
          className="flex items-center">
          <img
            className="ml-2 w-10 h-10 rounded-full object-cover"
            src={
              data.user?.photoURL ||
              "https://cdn.pixabay.com/photo/2017/11/10/05/48/user-2935527_1280.png"
            }
            alt="User profile"
          />
          <div className="ml-2 flex flex-col items-start text-sm">
            <p className="font-semibold">{data.user?.fullName}</p>
            <p>{data.user.roles.map((role) => role.name).join(", ")}</p>
          </div>
        </Link>
      </div>
      {/* Middle */}
      <div
        className={`message-content h-full text-gray-600 p-1 flex flex-col justify-end ${
          getMessagesError && "justify-center items-center"
        } w-full vertical-scrollbar`}>
        {getMessagesError ? (
          <p className="font-semibold flex items-center bg-light-gray px-3 py-1 rounded-full text-sm">
            <Warning className="!w-4 !h-4 mr-1" />
            {getMessagesError.message}
          </p>
        ) : getMessagesLoading ? (
          <p className="font-semibold flex items-center m-auto bg-light-gray px-3 py-1 rounded-full text-sm">
            Loading chats...
          </p>
        ) : messages.length === 0 ? (
          <div className="m-auto">
            <img className="w-[150px]" src={noChatFound} alt="" />
            <p className="text-sm text-center mt-2 font-semibold">
              No messages yet
            </p>
          </div>
        ) : (
          messages.map((message, index) => (
            <ChatItem currentUser={user} message={message} key={index} />
          ))
        )}
      </div>
      {/* Bottom */}
      <form
        className="w-full sticky bottom-0 flex bg-opacity-10 bg-white"
        style={{ backdropFilter: `blur(20px)` }}
        onSubmit={handleSend}>
        <textarea
          ref={inputRef}
          id="chat"
          autoFocus={true}
          // value={messageText}
          // onChange={messageChangeHandler}
          rows={lines}
          className="resize-none border-none focus:ring-0 bg-inherit outline-none block p-2.5 w-full text-sm text-gray-600 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          placeholder="Write a message..."></textarea>
        <button
          type="submit"
          className="inline-flex justify-center p-2 text-secondary bg-white/20 hover:bg-white/40 cursor-pointer  dark:text-blue-500 dark:hover:bg-gray-600">
          <svg
            aria-hidden="true"
            className="w-6 h-6 rotate-90"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
          </svg>
          <span className="sr-only">Send</span>
        </button>
      </form>
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
    </div>
  );
};

export default Chats;
