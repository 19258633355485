import React, { useEffect, useRef, useState } from "react";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import Textarea from "../UI/Textarea";
import Progress from "../UI/Progress";
import { v4 } from "uuid";
import { useProject } from "../../store/project-context";
import Compressor from "compressorjs";
import Image from "../UI/Image";
import DragAndDrop from "../UI/DragAndDrop";
import IconButton from "../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadImages } from "../../functions/api";

const INSERT_IMAGE = gql`
  mutation MyMutation($objects: [projects_images_insert_input!]!) {
    insert_projects_images(objects: $objects) {
      affected_rows
    }
  }
`;

const UPDATE_IMAGE = gql`
  mutation MyMutation3(
    $url: String!
    $id: uuid!
    $groupId: uuid!
    $description: String!
  ) {
    update_projects_images_by_pk(pk_columns: { id: $id }, _set: { url: $url }) {
      id
    }
    update_projects_images(
      where: { groupId: { _eq: $groupId } }
      _set: { description: $description }
    ) {
      affected_rows
    }
  }
`;

// const GET_USERS = gql`
//   query MyQuery($id: uuid!) {
//     projects_projects_by_pk(id: $id) {
//       user_projects {
//         userId
//       }
//     }
//   }
// `;

const INSERT_NOTIFICATIONS = gql`
  mutation MyMutation($objects: [users_notifications_insert_input!]!) {
    insert_users_notifications(objects: $objects) {
      affected_rows
    }
  }
`;
const NewImage = (props) => {
  const { currentProject, refetchProjects } = useProject();
  const [images, setImages] = useState(null); // before upload
  const [uploadedImages, setUploadedImages] = useState([]); // after upload
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const browseImage = useRef();
  const [addImages, { loading: isImagesSubmitting, error: insertImagesError }] =
    useMutation(INSERT_IMAGE);

  const [
    updateImages,
    { loading: isImagesUpdating, error: updateImagesError },
  ] = useMutation(UPDATE_IMAGE);
  const [insertNotifications] = useMutation(INSERT_NOTIFICATIONS);
  const {
    // setSelectedImage,
    selectedImage,
    editMode,
    setError,
    setSuccess,
    lastUploaded,
  } = props;
  const {
    value: description,
    isValid: descriptionIsValid,
    isInvalid: descriptionIsInValid,
    inputChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
  } = useInput((value) => value.trim() !== "");
  let formIsValid = descriptionIsValid && uploadedImages.length > 0;
  const submitHandler = async (e) => {
    try {
      setLoading(true);
      if (!editMode) {
        const finalImages = await uploadImages({
          images,
          setUploadProgress,
          setError,
        });
        const groupId = v4();
        const lastNumber = lastUploaded || 0;
        console.log(lastUploaded);
        console.log(lastNumber);
        if (!currentProject?.id) {
          setError("No project selected");
          return;
        }
        let imagesData = [];
        finalImages.map((url, index) => {
          const fileExtension = url.slice(-4);
          const image = {
            url,
            imageName:
              lastUploaded !== undefined
                ? currentProject?.projectName +
                  " " +
                  parseInt(index + parseInt(lastNumber) + 1) +
                  fileExtension
                : currentProject?.projectName +
                  " " +
                  parseInt(index + 1) +
                  fileExtension,
            groupId,
            projectId: currentProject?.id,
            description,
            sequence: parseInt(lastNumber) + index + 1,
          };
          imagesData.push(image);
        });
        const imagesResponse = await addImages({
          variables: { objects: imagesData },
        });
        if (imagesResponse.data.insert_projects_images.affected_rows) {
          let notifications = [];
          const user_ids = currentProject.user_projects.map(
            (user_project) => user_project.user.id
          );

          user_ids.map((user_id) => {
            notifications.push({
              tag: "IMAGE",
              title: `${uploadedImages.length} ${
                uploadedImages.length > 1 ? "images" : "image"
              } uploaded for ${currentProject?.projectName}`,
              link: "/images",
              description: `${uploadedImages.length} ${
                uploadedImages.length > 1 ? "images" : "image"
              } has been uploaded. Check out the progress.`,
              user_id,
            });
          });
          await insertNotifications({
            variables: {
              objects: notifications,
            },
          });
          if (!insertImagesError && !isImagesSubmitting) {
            setSuccess(
              `${uploadedImages.length} ${
                uploadedImages.length > 1 ? "images" : "image"
              }
              uploaded for ${currentProject?.projectName}`
            );
          }
        }
      } else {
        if (selectedImage) {
          const response = await updateImages({
            variables: {
              id: selectedImage.id,
              url: uploadedImages,
              description: description,
              groupId: selectedImage.groupId,
            },
          });
          if (response?.data?.update_projects_images_by_pk?.id) {
            setSuccess("Image updated successfully");
          }
        }
      }
      setLoading(false);
      props.hideModal();
      props.refetch();
      refetchProjects();
    } catch (e) {
      setLoading(false);
      setError(e?.message);
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedImage && editMode) {
      descriptionChangeHandler(selectedImage?.description);
      setUploadedImages(selectedImage?.url);
    }
  }, [selectedImage, editMode, descriptionChangeHandler]);

  const deleteImage = (image, index) => {
    setUploadedImages(uploadedImages.filter((img) => img !== image));
    const values = images.getAll("images[]");
    images.delete("images[]");

    values
      .filter((_, i) => i !== index)
      .forEach((img) => images.append("images[]", img));
  };

  function handleImageUpload(files) {
    console.log(files);
    const formData = new FormData();
    let newImages = [];
    Object.values(files).map(async (file, index) => {
      const compressedBlob = await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            resolve(result);
          },
          error(error) {
            reject(error);
          },
        });
      });
      newImages.push(URL.createObjectURL(compressedBlob));
      setUploadedImages([...uploadedImages, ...newImages]);
      formData.append("images[]", compressedBlob, compressedBlob.name);
    });
    setImages(formData);
  }
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[85%] sm:h-[97%]"
          isShown={true} //props.isShown
          hideModal={props.hideModal}
          modalTitle={editMode ? "Edit Image" : "Add New Image"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={props.hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={loading || isImagesSubmitting || isImagesUpdating}
                onClick={submitHandler}
                type="submit">
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          }>
          <p className="text-dark-gray text-sm ">
            {editMode
              ? "Edit basic information of the image you selected"
              : "Add new photos of the current construction site with the progress details"}
          </p>
          <form
            encType="multipart/form-data"
            className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            {/* <button type="button" onClick={uploadImages}>
              Upload!
            </button> */}
            {!editMode ? (
              <div className="flex flex-col justify-center w-full">
                <label
                  htmlFor="input-group-1"
                  className={`block text-start w-fit text-sm font-medium text-gray-600 dark:text-white`}>
                  Select Images <span className="text-red-500">*</span>
                </label>
                <span className="text-dark-gray mb-2 text-xs">
                  Maximum 10 images
                </span>
                <DragAndDrop
                  onDrop={handleImageUpload}
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full min-h-[12rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointerdark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16">
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      JPG, PNG or TIFF (MAX. 10MB)
                    </p>
                  </div>
                  <input
                    accept="image/jpeg, image/jpg, image/png, image/tiff"
                    multiple
                    onChange={(event) => {
                      handleImageUpload(event.target.files);
                    }}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </DragAndDrop>
                {(uploadedImages.length || uploadProgress !== 0) && (
                  <div className="bg-gray-50 p-3 rounded-lg mt-3 overflow-auto horizontal-scrollbar">
                    <div className="flex flex-wrap justify-center gap-2">
                      {uploadedImages.map((image, index) => (
                        <div key={index} className="group relative">
                          <Image
                            width="w-12"
                            height="h-12"
                            skeletonWidth="w-10"
                            skeletonHeight="h-10"
                            key={index}
                            src={image}
                            alt="Project"
                            className="object-cover rounded-md"
                          />
                          <IconButton
                            className="hidden group-hover:flex absolute top-0 bottom-0 left-0 right-0 m-auto border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                            type="rounded"
                            onClick={() => {
                              deleteImage(image, index);
                            }}>
                            <DeleteIcon className="!w-4 !h-4" />
                          </IconButton>
                        </div>
                      ))}
                    </div>
                    {uploadProgress !== 0 && (
                      <Progress
                        className="w-full mt-2"
                        progress={uploadProgress}
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="group">
                <label className="flex flex-col items-center justify-center w-full min-h-[12rem] rounded-lg bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div
                    className="w-full h-48 rounded-lg "
                    style={{
                      backgroundImage: `url(${
                        uploadedImages || selectedImage.url
                      })`,
                      backgroundSize: "cover",
                    }}>
                    <div
                      className="relative h-full w-full rounded-lg flex items-center justify-center bg-clip-padding bg-opacity-20"
                      style={{ backdropFilter: `blur(5px)` }}>
                      <Button
                        className="hidden group-hover:block absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 border !bg-black/20 hover:!bg-black/40 !ring-white/20 text-white"
                        type="light"
                        onClick={() => browseImage.current.click()}>
                        Choose Image
                      </Button>
                      <Image
                        width="w-4/5"
                        height="h-4/5"
                        skeletonWidth="w-54"
                        skeletonHeight="h-54"
                        src={uploadedImages || selectedImage.url}
                        alt="Project"
                        className="object-cover rounded-lg"
                      />
                    </div>
                    <input
                      accept="image/jpeg, image/jpg, image/png, image/tiff"
                      multiple
                      onChange={(event) => {
                        handleImageUpload(event.target.files);
                      }}
                      id="dropzone-file"
                      type="file"
                      ref={browseImage}
                      className="hidden"
                    />
                  </div>
                </label>
                {uploadProgress !== 0 && (
                  <Progress className="w-full mt-2" progress={uploadProgress} />
                )}
              </div>
            )}
            <Textarea
              label="Description"
              onChange={descriptionChangeHandler}
              onBlur={descriptionBlurHandler}
              value={description}
              error={descriptionIsInValid}
              id="description"
              placeholder="Add description"
              helperText={<>A valid description is required</>}
              required
            />
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewImage;
