import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import buildingIllustration from "../../assets/illustrations/building.svg";
import { Link } from "react-router-dom";
import { ArrowRightShort } from "react-bootstrap-icons";
import Button from "./Button";

const SiteCards = ({ site, className, onClick, selectedSite }) => {
  return (
    <button
      onClick={onClick}
      className={`
      ${
        selectedSite?.id === site.id
          ? "bg-primary/10 hover:!bg-primary/10"
          : "hover:bg-gray-100"
      }
      flex w-[30rem] max-w-full h-64 shadow-md overflow-hidden hover:shadow-lg hover:scale-[1.03] transition-transform cursor-pointer rounded-xl dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 ${className}`}
    >
      <img
        className="object-cover w-full rounded-t-lg h-full md:w-48 md:rounded-none md:rounded-s-lg"
        src={site.image || buildingIllustration}
        alt=""
      />
      <div className="flex flex-col w-full items-start border-l-2 h-full justify-between p-4 leading-normal">
        <div className="h-3/5 overflow-hidden">
          <h5 className="mb-2 text-2xl text-start font-semibold tracking-tight text-gray-600 dark:text-white">
            {site.name}
          </h5>
          <p className="mb-3 break-words text-start text-sm font-normal text-gray-500 dark:text-gray-400">
            {site.description}
          </p>
        </div>
        <div className="w-full grid">
          <p className="flex items-center text-xs font-semibold">
            <LocationOnOutlinedIcon className="!w-5 !h-5 text-dark-red" />
            {site.location}
          </p>
          <Link
            className="text-primary mt-2 w-fit justify-self-end text-sm flex justify-end items-center hover:underline group"
            to={`/sites/${site.id}/projects`}
          >
            <Button className="shadow-sm flex items-center !py-1 w-full hover:shadow-lg !bg-primary/10 hover:!bg-primary/20 !text-primary focus:!ring-0">
              <span>View projects</span>
              <ArrowRightShort className="text-2xl group-hover:translate-x-1" />
            </Button>
          </Link>
        </div>
      </div>
    </button>
  );
};

export default SiteCards;
