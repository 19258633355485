import { gql, useQuery } from "@apollo/client";
import React, { useContext } from "react";
// import { useAuthUser } from "react-auth-kit";

const UserContext = React.createContext();

const GET_USERS = gql`
  query MyQuery($where: users_users_bool_exp) {
    users_users(where: $where, order_by: { created_at: desc }) {
      email
      fullName
      phone
      created_at
      id
      photoURL
      user_roles {
        role {
          code
          id
          name
        }
      }
      user_projects(where: { project: { deleted_at: { _is_null: true } } }) {
        project {
          projectName
          id
        }
      }
      user_sites(where: { site: { deleted_at: { _is_null: true } } }) {
        site {
          name
          id
        }
      }
    }
  }
`;

export function useUser() {
  return useContext(UserContext);
}

export function UserContextProvider(props) {
  // const auth = useAuthUser();
  // const user = auth();
  // let variables = {};
  // const isSuperAdmin = user?.roles?.map((role) => role.code).includes("SU");
  // if (!isSuperAdmin) {
  //   variables = {
  //     where: { company_id: { _eq: user.company_id } },
  //   };
  // }
  const { data, loading, error, refetch, networkStatus } = useQuery(GET_USERS, {
    // variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });
  console.log("Inside user context");
  let users = data?.users_users.map((user) => {
    const roles = user.user_roles.map((user_role) => {
      return user_role.role;
    });
    return { ...user, roles };
  });

  const contextValue = {
    users,
    loading,
    error,
    refetch,
    networkStatus,
  };
  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
