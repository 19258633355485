import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  from,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

import { AuthProvider } from "react-auth-kit";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context"; // Import setContext
import { onError } from "@apollo/client/link/error";
import { ToastContainer, toast } from "react-toastify";
// HTTP link for queries and mutations
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT,
});

// SetContext link for dynamic token handling
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      headers,
    };
  }
});

// WebSocket link for subscriptions
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem("token");
      if (token) {
        return {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      }
    },
  },
});

// Combine links: dynamically determine if the operation is a subscription
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink) // Combine authLink and httpLink
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (
    (graphQLErrors && graphQLErrors[0].extensions.code === "invalid-jwt") ||
    (networkError && networkError.message.indexOf("JWTExpired") !== -1)
  ) {
    toast.error("Session Expired");
    window.location.replace("/");
    return;
  } else if (graphQLErrors) {
    toast.error(
      graphQLErrors
        ? graphQLErrors.map((error) => error.message).join("<br/>")
        : ""
    );

    // If there is a graphgl request error, the join all error messages and show them.
    // app.$notify({
    //   group: "all",
    //   title: "Error",
    //   text: `${graphQLErrors
    //     ? graphQLErrors.map((error) => error.message).join("<br/>")
    //     : ""
    //     }`,
    //   type: "error",
    // });
  }

  if (networkError) {
    toast.error("Couldn't connect to server.");

    // app.$notify({
    //   group: "all",
    //   title: "Network Error",
    //   text: `Couldn't connect to server.`,
    //   type: "info",
    // });
  }
});
// Apollo client setup
const client = new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider
    authType={"cookie"}
    authName={"_auth"}
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === "https:"}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <React.StrictMode>
          <ToastContainer />
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </ApolloProvider>
  </AuthProvider>
);
