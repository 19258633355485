import React from "react";

const InputMini = ({type, value, onChange, className}) => {
  return (
    <input
      type={type}
      value={value}
      onChange={onChange}
      className={`border p-1 w-8 outline-none border-gray-300 text-sm focus:border-primary rounded-lg min-w-[50px] ${className}`}
    />
  );
};

export default InputMini;
