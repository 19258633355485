import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/use-input";
import Input from "../../UI/Input";
import IconButton from "../../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Check2, PlusCircleFill, X } from "react-bootstrap-icons";
import { usePhase } from "../../../store/phase-context";
import Dropdown from "../../UI/Dropdown";
import { Check } from "@mui/icons-material";
import InputMini from "../../UI/InputMini";

const QuantifiableTasks = (props) => {
  const {
    scrollToBottom,
    tasks,
    setTasks,
    setSteps,
    steps,
    step,
    editMode,
    deletedItems,
    setDeletedItems,
    commonTasks,
  } = props;
  // const [editedItem, setEditedItem] = useState(null);
  const { storePhaseData } = usePhase();
  const {
    value: name,
    isValid: nameIsValid,
    // isInvalid: nameIsInValid,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput((value) => value.trim() !== "");

  const {
    value: unit,
    isValid: unitIsValid,
    // isInvalid: unitIsInValid,
    inputChangeHandler: unitChangeHandler,
    inputBlurHandler: unitBlurHandler,
    reset: resetUnit,
  } = useInput((value) => value.trim() !== "");

  const {
    value: quantity,
    isValid: quantityIsValid,
    // isInvalid: quantityIsInValid,
    inputChangeHandler: quantityChangeHandler,
    inputBlurHandler: quantityBlurHandler,
    reset: resetQuantity,
  } = useInput((value) => value !== "");

  const {
    value: unitRate,
    isValid: unitRateIsValid,
    // isInvalid: unitIsInValid,
    inputChangeHandler: unitRateChangeHandler,
    inputBlurHandler: unitRateBlurHandler,
    reset: resetUnitRate,
  } = useInput((value) => value !== "");

  const formIsValid = tasks.length;
  useEffect(() => {
    if (formIsValid) {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    } else {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            return { ...s, isValid: false };
          } else {
            return s;
          }
        })
      );
    }
  }, [formIsValid]);

  const removeItem = (index) => {
    if (editMode) {
      setDeletedItems([...deletedItems, tasks[index]?.id]);
    }
    setTasks(tasks.filter((_, i) => i != index));
    scrollToBottom();
  };
  const addItem = () => {
    if (nameIsValid && unitIsValid && quantityIsValid && unitRateIsValid) {
      const newTask = [...tasks, { name, unit, quantity, unitRate }];
      setTasks(newTask);
      if (!editMode) {
        storePhaseData({
          tasks: newTask,
        });
      }
      resetName();
      resetUnit();
      resetQuantity();
      resetUnitRate();
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (!tasks.length) {
      const newTasks = commonTasks.map((task) => {
        return { name: task.name, unit: task.unit, quantity: 1, unitRate: 0 };
      });
      setTasks(newTasks);
    }
  }, []);
  // const selectItem = (item, index) => {
  //   if (item) {
  //     setEditedItem({ item, index });
  //     nameChangeHandler(item?.name);
  //     unitChangeHandler(item?.unit);
  //     quantityChangeHandler(item?.quantity);
  //     unitRateChangeHandler(item?.unitRate);
  //     scrollToBottom();
  //   }
  // };
  // const applyItem = () => {
  //   const { item, index } = editedItem;
  //   if (item && formIsValid) {
  //     const updatedTask = tasks.map((item, i) => {
  //       if (index === i) {
  //         if (editMode) {
  //           return { ...item, name, unit, quantity, unitRate };
  //         } else {
  //           return { name, unit, quantity, unitRate };
  //         }
  //       } else return item;
  //     });
  //     setTasks(updatedTask);
  //     if (!editMode) {
  //       storePhaseData({
  //         tasks: updatedTask,
  //       });
  //     }
  //     resetName();
  //     resetUnit();
  //     resetQuantity();
  //     resetUnitRate();
  //     setEditedItem(null);
  //     scrollToBottom();
  //   }
  // };
  const onTaskChange = (e) => {
    nameChangeHandler(e.name);
    unitChangeHandler(e.unit);
    unitRateChangeHandler(e.unitRate);
    console.log(e);
  };
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedTask, setEditedTask] = useState(null);

  // Function to handle edit button click
  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedTask({ ...tasks[index] });
  };

  // Function to update input values
  const handleChange = (e, field) => {
    setEditedTask({ ...editedTask, [field]: e.target.value });
  };

  // Function to save changes
  const handleSave = () => {
    const updatedTasks = [...tasks];
    updatedTasks[editingIndex] = editedTask;
    setTasks(updatedTasks);
    setEditingIndex(null);
    storePhaseData({
      tasks: updatedTasks,
    });
  };
  return (
    <div className="space-y-3 flex flex-col justify-between h-fit">
      <h1 className="font-semibold">Quantifiable tasks</h1>
      <Dropdown
        selectHandler={onTaskChange}
        // onBlur={materialBlurHandler}
        // value={commonTask}
        // error={materialIsInValid}
        label="Search for tasks"
        placeholder="Eg. Area cleaning, Digging"
        items={commonTasks}
        propertyName="name"
        name="task"
      />
      <Input
        onChange={nameChangeHandler}
        onBlur={nameBlurHandler}
        value={name}
        // error={nameIsInValid}
        label="Task name"
        helperText={<>Task name is required</>}
        placeholder="1000 m2 of digging"
        required={true}
        name="name"
        type="text"
      />
      <div className="flex justify-between space-x-2 w-full">
        <Input
          className="w-1/2"
          value={unit}
          onChange={unitChangeHandler}
          onBlur={unitBlurHandler}
          // error={unitIsInValid}
          label="Unit"
          helperText={<>Unit is required</>}
          placeholder="m sq."
        />
        <Input
          className="w-1/2"
          value={quantity}
          onChange={quantityChangeHandler}
          onBlur={quantityBlurHandler}
          // error={quantityIsInValid}
          helperText={<>Quantity is required</>}
          label="Quantity"
          placeholder="15"
          type="number"
        />
      </div>
      <Input
        value={unitRate}
        onChange={unitRateChangeHandler}
        onBlur={unitRateBlurHandler}
        // error={unitIsInValid}
        label="Unit rate"
        helperText={<>Unit rate is required</>}
        placeholder="1000"
        type="number"
      />
      {/* {editedItem !== null ? (
        <span className="flex justify-between">
          <button
            disabled={!(nameIsValid && unitIsValid && quantityIsValid)}
            type="button"
            className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
            onClick={() => applyItem()}>
            <Check2 className="mr-1 text-[15px]" />
            Update item
          </button>
          <button
            disabled={!(nameIsValid && unitIsValid && quantityIsValid)}
            type="button"
            className="mt-2 disabled:text-red-400 hover:text-red-500 text-red-500/80 flex items-center p-1 text-sm"
            onClick={() => {
              setEditedItem(null);
              resetName();
              resetUnit();
              resetQuantity();
              resetUnitRate();
            }}>
            <X className="mr-1 text-[15px]" />
            Cancel
          </button>
        </span>
      ) : ( */}
      <button
        disabled={!(nameIsValid && unitIsValid && quantityIsValid)}
        type="button"
        className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
        onClick={addItem}>
        <PlusCircleFill className="mr-1 text-[15px]" />
        Add item
      </button>
      {/* )} */}
      <div>
        {tasks.length !== 0 && (
          <table className="w-full horizontal-scrollbar block  text-xs text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Task name
                </th>
                <th scope="col" className="px-6 py-3">
                  Unit
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                  Unit rate
                </th>
                <th scope="col" className=""></th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <td className="px-6 py-2 dark:text-white">
                    {editingIndex === index ? (
                      <InputMini
                        className="w-full"
                        type="text"
                        value={editedTask.name}
                        onChange={(e) => handleChange(e, "name")}
                      />
                    ) : (
                      item.name
                    )}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {editingIndex === index ? (
                      <InputMini
                        type="text"
                        value={editedTask.unit}
                        onChange={(e) => handleChange(e, "unit")}
                      />
                    ) : (
                      item.unit
                    )}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {editingIndex === index ? (
                      <InputMini
                        type="number"
                        value={editedTask.quantity}
                        onChange={(e) => handleChange(e, "quantity")}
                      />
                    ) : (
                      item.quantity
                    )}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                    {editingIndex === index ? (
                      <InputMini
                        type="number"
                        value={editedTask.unitRate}
                        onChange={(e) => handleChange(e, "unitRate")}
                      />
                    ) : (
                      item.unitRate
                    )}
                  </td>
                  <td className="flex py-2">
                    {editingIndex === index ? (
                      <IconButton
                        className="border-none hover:bg-gray-50 text-gray-400 w-6 h-6"
                        type="rounded"
                        onClick={handleSave}>
                        <Check className="!w-5 !h-5" />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="border-none hover:bg-gray-50 text-gray-400 w-6 h-6"
                        type="rounded"
                        onClick={() => handleEdit(index)}>
                        <EditIcon className="!w-4 !h-4" />
                      </IconButton>
                    )}
                    <IconButton
                      className="group-hover:flex border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                      type="rounded"
                      onClick={() => removeItem(index)}>
                      <DeleteIcon className="!w-4 !h-4" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default QuantifiableTasks;
