import React, { useEffect, useState } from "react";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import { gql, useMutation } from "@apollo/client";
// import { useAuthUser } from "react-auth-kit";
import { useUser } from "../../store/user-context";
import { useProject } from "../../store/project-context";
import Dropdown from "../UI/Dropdown";
import { X } from "react-bootstrap-icons";
const INSERT_USER_SITES = gql`
  mutation MyMutation($objects: [users_user_sites_insert_input!]!) {
    insert_users_user_sites(
      objects: $objects
      on_conflict: {
        constraint: user_sites_site_id_user_id_key
        update_columns: updated_at
      }
    ) {
      affected_rows
    }
  }
`;

const AddSite = (props) => {
  const [siteUsers, setSiteUsers] = useState([]);
  const { selectedSite, selectedRole, setError, setSuccess } = props;
  const [selectedSiteUsers, setSelectedSiteUsers] = useState([]);
  // const [newUserModalShown, setNewUserModalShown] = useState(false);
  // const auth = useAuthUser();
  // const user = auth();
  const users = useUser();
  const { refetchSites } = useProject();

  const [
    insertUserSites,
    { loading: isSiteSubmitting, error: insertSiteError },
  ] = useMutation(INSERT_USER_SITES);

  useEffect(() => {
    if (!users.loading && users?.users?.length) {
      setSiteUsers(
        users?.users.filter((user) =>
          user.roles.find((role) => role.code === selectedRole)
        )
      );
    }
  }, [users, selectedRole]);

  const formIsValid = selectedSiteUsers.length;

  const submitHandler = async (e) => {
    if (!formIsValid) {
      return;
    }
    try {
      if (selectedSite) {
        const objects = selectedSiteUsers.map((siteUser) => {
          return {
            user_id: siteUser?.id,
            site_id: selectedSite.id,
          };
        });
        const response = await insertUserSites({
          variables: { objects },
        });
        console.log(response);
        
        if (response?.data?.insert_users_user_sites?.affected_rows) {
          setSuccess(
            `${objects.length} user${
              objects.length > 1 ? "s" : ''
            } added successfully`
          );
          hideModal();
          users?.refetch();
          refetchSites();
          props?.refetch();
        }
      }
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  const addSiteUser = (item, index) => {
    console.log(item);
    console.log(selectedSite);
    let found = false;
    if (selectedRole === "ST_KP") {
      selectedSite.store_keepers.map((store_keeper) => {
        if (item.id === store_keeper.user.id) {
          setError("User is already added to this site");
          found = true;
          return;
        }
      });
    } else if (selectedRole === "SITE_ADM") {
      selectedSite.site_admins.map((site_admin) => {
        if (item.id === site_admin.user.id) {
          setError("User is already added to this site");
          found = true;
          return;
        }
      });
    }
    if (selectedSiteUsers.indexOf(item.fullName) > -1) {
      return;
    }
    selectedSiteUsers.map((siteUser) => {
      if (item.id === siteUser.id) {
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    setSelectedSiteUsers((siteUsers) => [
      { id: item.id, fullName: item.fullName },
      ...siteUsers,
    ]);
  };
  const removeSiteUser = (i) => {
    setSelectedSiteUsers(selectedSiteUsers.filter((_, index) => i !== index));
  };
  const hideModal = () => {
    setSelectedSiteUsers([]);
    // setNewUserModalShown(false);
    props.hideModal();
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[300px]"
          isShown={true} //props.isShown
          hideModal={hideModal}
          modalTitle={"Add a new user to this site"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={isSiteSubmitting}
                onClick={submitHandler}
                type="submit">
                Save
              </Button>
            </div>
          }>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            <Dropdown
              selectHandler={addSiteUser}
              id="siteUser"
              label={
                selectedRole === "SITE_ADM"
                  ? "Site admin"
                  : selectedRole === "ST_KP"
                  ? "Store keeper"
                  : ""
              }
              helperText={
                <>
                  {selectedRole === "SITE_ADM"
                    ? "Site admin is required"
                    : selectedRole === "ST_KP"
                    ? "Store keeper is required"
                    : ""}
                </>
              }
              loading={users?.loading}
              errorMessage={users?.error?.message}
              items={siteUsers}
              propertyName="fullName"
            />
            <div className="w-first flex space-x-3">
              {selectedSiteUsers.length !== 0 &&
                selectedSiteUsers.map((siteUser, index) => (
                  <span
                    key={index}
                    className="bg-primary/10 flex items-center text-primary text-xs rounded-md px-3 py-1">
                    {siteUser.fullName}
                    <button
                      onClick={() => removeSiteUser(index)}
                      type="button"
                      className="text-lg ml-2 hover:text-red-500">
                      <X />
                    </button>
                  </span>
                ))}
            </div>
            {/* <Button
              onClick={() => {
                setNewUserModalShown(true);
              }}
            >
              Create
            </Button> */}
          </form>
        </Modal>
        {/* {newUserModalShown && (
          <NewUser
            refetch={users.refetch}
            setSuccess={setSuccess}
            setError={setError}
            isShown={newUserModalShown}
            hideModal={hideModal}
          />
        )} */}
      </>
    );
  } else {
    return <></>;
  }
};

export default AddSite;
