import React, { useEffect, useState } from "react";
import Input from "../components/UI/Input";
import Button from "../components/UI/Button";
import Card from "../components/UI/Card";
import { gql, useMutation } from "@apollo/client";
import useInput from "../hooks/use-input";
import Toast from "../components/UI/Toast";
import { useNavigate } from "react-router-dom";
import LockOutlineIcon from "@mui/icons-material/LockOutlined";

const UPDATE_PASSWORD = gql`
  mutation MyMutation($object: UpdatePasswordInput!) {
    update_password(object: $object) {
      status
    }
  }
`;

const PasswordPage = () => {
  const {
    value: oldPassword,
    isValid: oldPasswordIsValid,
    isInvalid: oldPasswordIsInValid,
    inputChangeHandler: oldPasswordChangeHandler,
    inputBlurHandler: oldPasswordBlurHandler,
  } = useInput((value) => value.trim() !== "");

  const {
    value: password1,
    isValid: password1IsValid,
    isInvalid: password1IsInValid,
    inputChangeHandler: password1ChangeHandler,
    inputBlurHandler: password1BlurHandler,
  } = useInput((value) => value.trim() !== "");

  const {
    value: password2,
    isValid: password2IsValid,
    isInvalid: password2IsInValid,
    inputChangeHandler: password2ChangeHandler,
    inputBlurHandler: password2BlurHandler,
  } = useInput((value) => value.trim() !== "");

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [
    updatePassword,
    { loading: isPasswordUpdating, error: updatePasswordError },
  ] = useMutation(UPDATE_PASSWORD);

  const formIsValid =
    oldPasswordIsValid && password1IsValid && password2IsValid;

  const submitHandler = async (e) => {
    if (!formIsValid) {
      return;
    }
    if (password1 !== password2) {
      setError("Passwords do not match");
      return;
    }
    if (password1.length < 6) {
      setError("Password must be more than 6 characters");
      return;
    }
    try {
      const response = await updatePassword({
        variables: {
          object: {
            new_password: password1,
            old_password: oldPassword,
          },
        },
      });
      if (response?.data?.update_password) {
        console.log(response);

        if (
          response.data.update_password.status === true &&
          !updatePasswordError
        ) {
          setSuccess("Password successfully updated");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      } else {
        setError("An error occurred while updating password");
      }
    } catch (e) {
      setError(e?.message);
    }
  };
  return (
    <div className="max-w-3xl mx-auto p-10">
      <Card className="shadow-sm">
        <h2 className="text-xl font-bold mb-14">Update Password</h2>
        <div className="flex flex-col gap-6">
          {/* Form Section */}
          <div className="flex-1">
            <div className="flex flex-col gap-10">
              <Input
                onChange={oldPasswordChangeHandler}
                onBlur={oldPasswordBlurHandler}
                value={oldPassword}
                error={oldPasswordIsInValid}
                id="oldPassword"
                label="Old password"
                type="password"
                icon={<LockOutlineIcon className="!text-lg" />}
                helperText={<>A valid password is required</>}
                placeholder="Password"
                required
                name="oldPassword"
              />
              <Input
                onChange={password1ChangeHandler}
                onBlur={password1BlurHandler}
                value={password1}
                error={password1IsInValid}
                id="password1"
                label="New password"
                type="password"
                icon={<LockOutlineIcon className="!text-lg" />}
                helperText={<>A valid password is required</>}
                placeholder="Password"
                required
                name="password1"
              />
              <Input
                onChange={password2ChangeHandler}
                onBlur={password2BlurHandler}
                value={password2}
                error={password2IsInValid}
                id="password2"
                label="Confirm password"
                icon={<LockOutlineIcon className="!text-lg" />}
                helperText={<>A valid password is required</>}
                placeholder="Password"
                required
                type="password"
                name="password1"
              />
            </div>

            <div className="flex justify-end gap-4 mt-10">
              <Button
                disabled={!formIsValid}
                isLoading={isPasswordUpdating}
                onClick={submitHandler}
                type="submit">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
      <Toast
        type="success"
        show={success !== null}
        callback={() => {
          setSuccess(null);
        }}
        message={success}
      />
    </div>
  );
};

export default PasswordPage;
