import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSignOut, useAuthUser } from "react-auth-kit";
import Confirm from "../UI/Confirm";
import { useMediaQuery } from "react-responsive";
import bg from "../../assets/illustrations/building.svg";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CollectionsIcon from "@mui/icons-material/Collections";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import PaidIcon from "@mui/icons-material/Paid";
import MessageIcon from "@mui/icons-material/Message";
import ArticleIcon from "@mui/icons-material/Article";
import FolderIcon from "@mui/icons-material/Folder";
import MapsHomeWorkRoundedIcon from "@mui/icons-material/MapsHomeWorkRounded";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import LogoutIcon from "@mui/icons-material/Logout";
import ConstructionIcon from "@mui/icons-material/Construction";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import { useProject } from "../../store/project-context";
import { ChatContext } from "../../store/chat-context";
import logo from "../../assets/images/Logo.png";
import { checkRoles } from "../../functions/validation";

const Sidebar = (props) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const [isConfirmShown, setIsConfirmShown] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1280px)",
  });
  const auth = useAuthUser();
  const user = auth();
  const { clear } = useProject();
  const navLinkClickHandler = () => {
    !isDesktopOrLaptop && props.setSidebarShown(false);
  };
  const { message_count } = useContext(ChatContext);
  useEffect(() => {
    if (user.roles.find((role) => role.code === "ST_KP")) {
      navigate("/storage");
    }
  }, [user]);
  return (
    <Fragment>
      <div
        onClick={() => {
          props.setSidebarShown(!props.sidebarShown);
        }}
        className={`xl:hidden ${
          !props.sidebarShown && "hidden"
        } z-10 bg-black/30 w-screen h-screen fixed top-0 left-0`}></div>
      <aside
        id="logo-sidebar"
        className={`bg-white z-10 text-sm sticky top-0 left-0 min-w-[200px] sm:min-w-[250px] h-screen overflow-auto ${props.className}`}
        aria-label="Sidebar">
        <div className="h-full flex flex-col justify-between dark:bg-gray-800">
          <div className="">
            <Link
              to="/"
              className="flex items-center justify-center pl-2.5 my-6">
              <img
                className="w-36 object-contain"
                src={logo}
                alt="Conify logo"
              />
            </Link>
            <ul className="space-y-2 2xl:space-y-4 font-medium mt-16">
              {checkRoles(user, ["SU", "ADM", "CNTR", "SITE_ADM"]) && (
                <>
                  <li>
                    <NavLink
                      onClick={navLinkClickHandler}
                      to="/"
                      className={({ isActive }) =>
                        `${
                          isActive &&
                          "!text-white !font-bold active bg-primary hover:!bg-primary"
                        } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10`
                      }>
                      <SpaceDashboardIcon />
                      <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={navLinkClickHandler}
                      to="/phases"
                      className={({ isActive }) =>
                        `${
                          isActive &&
                          "!text-white !font-bold active bg-primary hover:!bg-primary"
                        } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                      }>
                      <FactCheckIcon />

                      <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                        Phases
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={navLinkClickHandler}
                      to="/images"
                      className={({ isActive }) =>
                        `${
                          isActive &&
                          "!text-white !font-bold active bg-primary hover:!bg-primary"
                        } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                      }>
                      <CollectionsIcon />

                      <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                        Images
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              {checkRoles(user, ["ADM", "SU", "CNTR", "ST_KP", "SITE_ADM"]) && (
                <li>
                  <NavLink
                    onClick={navLinkClickHandler}
                    to="/storage"
                    className={({ isActive }) =>
                      `${
                        isActive &&
                        "!text-white !font-bold active bg-primary hover:!bg-primary"
                      } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                    }>
                    <WarehouseIcon />

                    <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                      Storage
                    </span>
                  </NavLink>
                </li>
              )}
              {checkRoles(user, ["ADM", "SU", "CNTR", "SITE_ADM"]) && (
                <li>
                  <NavLink
                    onClick={navLinkClickHandler}
                    to="/resources"
                    className={({ isActive }) =>
                      `${
                        isActive &&
                        "!text-white !font-bold active bg-primary hover:!bg-primary"
                      } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                    }>
                    <ConstructionIcon />

                    <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                      Resources
                    </span>
                  </NavLink>
                </li>
              )}
              {checkRoles(user, ["SU", "ADM", "CNTR", "SITE_ADM"]) && (
                <li>
                  <NavLink
                    onClick={navLinkClickHandler}
                    to="/budget"
                    className={({ isActive }) =>
                      `${
                        isActive &&
                        "!text-white !font-bold active bg-primary hover:!bg-primary"
                      } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                    }>
                    <PaidIcon />

                    <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                      Budget
                    </span>
                  </NavLink>
                </li>
              )}
              {checkRoles(user, ["SU", "ADM", "ST_KP", "CNTR", "SITE_ADM"]) && (
                <li>
                  <NavLink
                    onClick={navLinkClickHandler}
                    to="/messaging"
                    className={({ isActive }) =>
                      `${
                        isActive &&
                        "!text-white !font-bold active bg-primary hover:!bg-primary"
                      } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                    }>
                    <MessageIcon />

                    <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                      Message
                    </span>
                    {message_count > 0 && (
                      <span className="ml-1 flex items-center justify-center font-medium bg-dark-red text-white text-xs rounded-full min-w-[16px] h-4 px-1">
                        {message_count}
                      </span>
                    )}
                  </NavLink>
                </li>
              )}
              {checkRoles(user, ["SU", "ADM", "CNTR", "SITE_ADM"]) && (
                <>
                  <li>
                    <NavLink
                      onClick={navLinkClickHandler}
                      to="/report"
                      className={({ isActive }) =>
                        `${
                          isActive &&
                          "!text-white !font-bold active bg-primary hover:!bg-primary"
                        } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                      }>
                      <ArticleIcon />

                      <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                        Report
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={navLinkClickHandler}
                      to="/files"
                      className={({ isActive }) =>
                        `${
                          isActive &&
                          "!text-white !font-bold active bg-primary hover:!bg-primary"
                        } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                      }>
                      <FolderIcon />

                      <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                        Files
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              {/* ---- */}
              {checkRoles(user, ["ADM", "SU", "SITE_ADM"]) && (
                <span className="text-xs block text-start pl-7 text-dark-gray/80">
                  Management
                </span>
              )}
              {checkRoles(user, ["SU"]) && (
                <li>
                  <NavLink
                    onClick={navLinkClickHandler}
                    to="/companies"
                    className={({ isActive }) =>
                      `${
                        isActive &&
                        "!text-white !font-bold active bg-primary hover:!bg-primary"
                      } group font-medium flex items-center mt-2 w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                    }>
                    <ApartmentRoundedIcon />

                    <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                      Companies
                    </span>
                  </NavLink>
                </li>
              )}
              {checkRoles(user, ["ADM", "SU"]) && (
                <li>
                  <NavLink
                    onClick={navLinkClickHandler}
                    to="/users"
                    className={({ isActive }) =>
                      `${
                        isActive &&
                        "!text-white !font-bold active bg-primary hover:!bg-primary"
                      } group font-medium flex items-center w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                    }>
                    <PeopleAltIcon />

                    <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                      Users
                    </span>
                  </NavLink>
                </li>
              )}
              {checkRoles(user, ["ADM", "SITE_ADM"]) && (
                <li>
                  <NavLink
                    onClick={navLinkClickHandler}
                    to="/sites"
                    className={({ isActive }) =>
                      `${
                        isActive &&
                        "!text-white !font-bold active bg-primary hover:!bg-primary"
                      } group font-medium flex items-center mt-2 w-100 p-2 mx-3 rounded-lg text-dark-gray pl-7 dark:text-white hover:bg-primary/10 dark:hover:bg-gray-700`
                    }>
                    <MapsHomeWorkRoundedIcon />

                    <span className="ml-5 text-start text-dark-gray group-[.active]:text-white">
                      Sites
                    </span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          {/* <button
              onClick={() => {
                setIsConfirmShown(true);
              }}
              type="alternative"
              className="w-full z-10 text-dark-gray font-medium hover:bg-primary/10 rounded-none border-none border-t flex items-center pl-4 py-3 focus:ring-0"
            >
              <LogoutIcon className="text-lg mr-2 rotate-180" /> Log out
            </button> */}
        </div>
      </aside>
      {isConfirmShown && (
        <Confirm
          confirmButtonText="I'm sure"
          confirmTitle="Logout"
          onClick={() => {
            signOut();
            clear();
            navigate("/login");
          }}
          onCancel={() => {
            setIsConfirmShown(false);
          }}>
          Are you sure you want to logout?
        </Confirm>
      )}
    </Fragment>
  );
};

export default Sidebar;
