import React, { useEffect, useState } from "react";
import Input from "../components/UI/Input";
import Button from "../components/UI/Button";
import Card from "../components/UI/Card";
import { gql, useMutation } from "@apollo/client";
import { useAuthUser, useSignIn } from "react-auth-kit";
import useInput from "../hooks/use-input";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Image from "../components/UI/Image";
import IconButton from "../components/UI/IconButton";
import DragAndDrop from "../components/UI/DragAndDrop";
import { uploadImage } from "../functions/api";
import Compressor from "compressorjs";
import DeleteIcon from "@mui/icons-material/Delete";
import Toast from "../components/UI/Toast";
import { useNavigate } from "react-router-dom";
import Progress from "../components/UI/Progress";

const UPDATE_PROFILE = gql`
  mutation MyMutation($object: UpdateProfileInput!) {
    update_profile(object: $object) {
      status
    }
  }
`;

const ProfilePage = () => {
  const {
    value: fullName,
    isValid: fullNameIsValid,
    isInvalid: fullNameIsInValid,
    inputChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHandler,
  } = useInput((value) => value.trim() !== "");

  const {
    value: phone,
    isValid: phoneIsValid,
    isInvalid: phoneIsInValid,
    inputChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput((value) => value.trim() !== "" && isPossiblePhoneNumber(value));

  const {
    value: email,
    isValid: emailIsValid,
    isInvalid: emailIsInValid,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => value.trim() !== "");
  const auth = useAuthUser();
  const user = auth();
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const signIn = useSignIn();

  const [
    updateProfile,
    { loading: isProfileUpdating, error: updateProfileError },
  ] = useMutation(UPDATE_PROFILE);
  const deleteImage = () => {
    setUploadedImage(null);
    setImage(null);
  };

  function handleImageUpload(files) {
    Object.values(files).map(async (file) => {
      const formData = new FormData();

      const compressedBlob = await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            resolve(result);
          },
          error(error) {
            reject(error);
          },
        });
      });
      setImage(URL.createObjectURL(compressedBlob));
      formData.append("image", compressedBlob, compressedBlob.name);
      setUploadedImage(formData);
    });
  }
  const formIsValid = fullNameIsValid && phoneIsValid && emailIsValid;
  useEffect(() => {
    fullNameChangeHandler(user?.fullName);
    phoneChangeHandler(user?.phone);
    emailChangeHandler(user?.email);
    setImage(user?.photoURL);
  }, [
    user,
    fullNameChangeHandler,
    phoneChangeHandler,
    emailChangeHandler,
    setImage,
  ]);
  const submitHandler = async (e) => {
    if (!formIsValid) {
      return;
    }
    let userData = {
      id: user.id,
      fullName,
      phone,
      email,
    };

    if (uploadedImage) {
      userData.photoURL = await uploadImage({
        image: uploadedImage,
        setUploadProgress,
        setError,
      });
    } else if (user.photoURL) {
      if (image) {
        userData.photoURL = user.photoURL;
      } else {
        userData.photoURL = null;
      }
    }
    try {
      const response = await updateProfile({
        variables: { object: userData },
      });
      if (response?.data?.update_profile) {
        console.log(response);

        if (
          response.data.update_profile.status === true &&
          !updateProfileError
        ) {
          setSuccess("Profile successfully updated");
          setTimeout(() => {
            signIn({
              token: localStorage.getItem("token"),
              expiresIn: 3600,
              tokenType: "Bearer",
              authState: {
                ...user,
                ...userData,
              },
            });
            navigate("/");
          }, 2000);
        }
      } else {
        setError("An error occurred while updating profile");
      }
    } catch (e) {
      setError(e?.message);
    }
  };
  return (
    <div className="max-w-3xl mx-auto p-10">
      <Card className="shadow-sm">
        <h2 className="text-xl font-bold mb-14">Edit Profile</h2>
        <div className="flex flex-col gap-6">
          {/* Profile Picture Section */}
          <div className="flex flex-col items-center relative">
            {!image ? (
              <div className="flex flex-col pb-2 items-center justify-center mb-2">
                <DragAndDrop
                  onDrop={handleImageUpload}
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-44 h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16">
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-center text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    {/* <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                      JPG, PNG or TIFF (MAX. 10MB)
                    </p> */}
                  </div>
                  <input
                    accept="image/jpeg, image/jpg, image/png, image/tiff"
                    onChange={(event) => {
                      handleImageUpload(event.target.files);
                    }}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </DragAndDrop>
              </div>
            ) : (
              <div className="flex justify-center items-center space-x-2 mb-2 w-44 h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer">
                <div className="group relative">
                  <Image
                    width="w-36"
                    height="h-36"
                    skeletonWidth="w-10"
                    skeletonHeight="h-10"
                    src={image}
                    alt="Project"
                    className="object-contain rounded-md overflow-hidden"
                  />
                  <IconButton
                    className="hidden group-hover:flex absolute top-0 bottom-0 left-0 right-0 m-auto border-none bg-white/50 hover:bg-white/70 text-red-500 w-10 h-10"
                    type="rounded"
                    onClick={deleteImage}>
                    <DeleteIcon className="!w-6 !h-6" />
                  </IconButton>
                </div>
              </div>
            )}
            {uploadProgress !== 0 && (
              <Progress className="w-72 mt-2" progress={uploadProgress} />
            )}
          </div>

          {/* Form Section */}
          <div className="flex-1">
            <div className="flex flex-col gap-6">
              <Input
                onChange={fullNameChangeHandler}
                onBlur={fullNameBlurHandler}
                value={fullName}
                error={fullNameIsInValid}
                id="fullName"
                label="Full name"
                helperText={<>Full name is required</>}
                placeholder="Adam sandler"
                required
                name="name"
                type="text"
              />
              <Input
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                value={email}
                error={emailIsInValid}
                id="email"
                label="Email"
                fullName
                helperText={<>A valid email is required</>}
                placeholder="youremail@gmail.com"
                required
                type="email"
              />
              <div>
                <label
                  htmlFor="input-group-1"
                  className={`block w-fit text-sm font-medium text-gray-600 dark:text-white ${
                    phoneIsInValid &&
                    "block mb-2 text-sm font-medium text-red-600 dark:text-red-500"
                  }`}>
                  Phone
                </label>
                <PhoneInput
                  countryCallingCodeEditable={false}
                  international
                  onChange={phoneChangeHandler}
                  onBlur={phoneBlurHandler}
                  defaultCountry="ET"
                  value={phone}
                  id="phone"
                  label="Phone"
                  placeholder="9 00000000"
                  error={phoneIsInValid}
                  required
                  inputComponent={Input}
                />
              </div>
            </div>

            <div className="flex justify-end gap-4 mt-6">
              <Button
                disabled={!formIsValid}
                isLoading={isProfileUpdating}
                onClick={submitHandler}
                type="submit">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <Toast
        type="error"
        show={error !== null}
        callback={() => {
          setError(null);
        }}
        message={error}
      />
      <Toast
        type="success"
        show={success !== null}
        callback={() => {
          setSuccess(null);
        }}
        message={success}
      />
    </div>
  );
};

export default ProfilePage;
