import React, { useEffect, useState } from "react";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import useInput from "../../hooks/use-input";
import { gql, useMutation } from "@apollo/client";
import Textarea from "../UI/Textarea";
import PlaceComponent from "../UI/Places autocomplete";
import { useAuthUser } from "react-auth-kit";
import { uploadImage } from "../../functions/api";
import DragAndDrop from "../UI/DragAndDrop";
import IconButton from "../UI/IconButton";
import Progress from "../UI/Progress";
import Image from "../UI/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import Compressor from "compressorjs";
import { useUser } from "../../store/user-context";
import { useProject } from "../../store/project-context";
import Dropdown from "../UI/Dropdown";
import { X } from "react-bootstrap-icons";

const INSERT_SITE = gql`
  mutation MyMutation($object: sites_insert_input!) {
    insert_sites_one(object: $object) {
      id
    }
  }
`;

const INSERT_USER_SITES = gql`
  mutation MyMutation($objects: [users_user_sites_insert_input!]!) {
    insert_users_user_sites(objects: $objects) {
      affected_rows
    }
  }
`;
const UPDATE_SITE = gql`
  mutation MyMutation(
    $id: uuid!
    $oldUserIds: [uuid!]
    $objects: [users_user_sites_insert_input!]!
    $_set: sites_set_input!
  ) {
    update_sites_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
    delete_users_user_sites(
      where: { _and: { site_id: { _eq: $id }, user_id: { _in: $oldUserIds } } }
    ) {
      affected_rows
    }
    insert_users_user_sites(objects: $objects) {
      affected_rows
    }
  }
`;

const NewSite = (props) => {
  const [coordinates, setCoordinates] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [siteAdmins, setSiteAdmins] = useState([]);
  const { selectedSite, editMode, setError, setSuccess } = props;
  const [selectedSiteAdmins, setSelectedSiteAdmins] = useState([]);
  const auth = useAuthUser();
  const user = auth();
  const users = useUser();
  const { sites } = useProject();

  const [updateSite, { loading: isSiteUpdating, error: updateSiteError }] =
    useMutation(UPDATE_SITE);
  const [insertSite, { loading: isSiteSubmitting, error: insertSiteError }] =
    useMutation(INSERT_SITE);
  const [insertUserSites] = useMutation(INSERT_USER_SITES);

  const {
    value: name,
    isValid: nameIsValid,
    isInvalid: nameIsInValid,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetname,
  } = useInput((value) => value.trim() !== "");

  const {
    value: location,
    isValid: locationIsValid,
    isInvalid: locationIsInValid,
    inputChangeHandler: locationChangeHandler,
    inputBlurHandler: locationBlurHandler,
    reset: resetLocation,
  } = useInput((value) => value.trim() !== "");

  const {
    value: description,
    isValid: descriptionIsValid,
    isInvalid: descriptionIsInValid,
    inputChangeHandler: descriptionChangeHandler,
    inputBlurHandler: descriptionBlurHandler,
    reset: resetDescription,
  } = useInput((value) => value.trim() !== "");

  useEffect(() => {
    if (!users.loading && users?.users?.length) {
      setSiteAdmins(
        users?.users.filter((user) =>
          user.roles.find((role) => role.code === "SITE_ADM")
        )
      );
    }
  }, [users]);

  const formIsValid =
    nameIsValid &&
    locationIsValid &&
    descriptionIsValid &&
    selectedSiteAdmins.length;

  const deleteImage = () => {
    setUploadedImage(null);
    setImage(null);
  };

  const submitHandler = async (e) => {
    if (!formIsValid) {
      return;
    }

    let siteData = {
      name,
      location,
      coordinates: JSON.stringify(coordinates),
      description,
      company_id: user.company_id,
    };
    if (uploadedImage) {
      siteData.image = await uploadImage({
        image: uploadedImage,
        setUploadProgress,
        setError,
      });
    } else if (selectedSite && selectedSite.image) {
      if (image) {
        siteData.image = selectedSite.image;
      } else {
        siteData.image = null;
      }
    }
    try {
      if (selectedSite) {
        const objects = selectedSiteAdmins.map((siteAdmin) => {
          return {
            user_id: siteAdmin?.id,
            site_id: selectedSite.id,
          };
        });
        await updateSite({
          variables: {
            id: selectedSite.id,
            _set: siteData,
            oldUserIds: selectedSite?.site_admins?.map(
              (site_admin) => site_admin.user.id
            ),
            objects,
          },
        });
      } else {
        const response = await insertSite({
          variables: { object: siteData },
        });
        if (response.data?.insert_sites_one?.id && !isSiteSubmitting) {
          const objects = selectedSiteAdmins.map((siteAdmin) => {
            return {
              user_id: siteAdmin?.id,
              site_id: response.data?.insert_sites_one?.id,
            };
          });
          await insertUserSites({
            variables: { objects },
          });
        }
      }
      if (!insertSiteError && !editMode) {
        setSuccess("Site added successfully");
      } else if (!updateSiteError) {
        setSuccess("Site updated successfully");
      }
      hideModal();
      users?.refetch();
      props?.refetch();
    } catch (e) {
      setError(e?.message);
      console.log(e);
    }
  };
  function handleImageUpload(files) {
    Object.values(files).map(async (file) => {
      const formData = new FormData();

      const compressedBlob = await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success(result) {
            resolve(result);
          },
          error(error) {
            reject(error);
          },
        });
      });
      setImage(URL.createObjectURL(compressedBlob));
      formData.append("image", compressedBlob, compressedBlob.name);
      setUploadedImage(formData);
    });
  }
  const addSiteAdmin = (item, index) => {
    console.log(item);
    console.log(selectedSiteAdmins);
    if (selectedSiteAdmins.indexOf(item.fullName) > -1) {
      return;
    }
    let found = false;
    selectedSiteAdmins.map((siteAdmin) => {
      if (item.id === siteAdmin.id) {
        found = true;
        return;
      }
    });
    if (found) {
      return;
    }
    setSelectedSiteAdmins((siteAdmins) => [
      { id: item.id, fullName: item.fullName },
      ...siteAdmins,
    ]);
  };
  const removeSiteAdmin = (i) => {
    setSelectedSiteAdmins(selectedSiteAdmins.filter((_, index) => i !== index));
  };
  useEffect(() => {
    if (selectedSite && editMode) {
      nameChangeHandler(selectedSite?.name);
      setSelectedSiteAdmins(
        selectedSite?.site_admins?.map((site_admin) => site_admin.user)
      );
      locationChangeHandler(selectedSite?.location);
      descriptionChangeHandler(selectedSite?.description);
      setImage(selectedSite?.image);
    }
  }, [
    editMode,
    selectedSite,
    setSelectedSiteAdmins,
    nameChangeHandler,
    locationChangeHandler,
    descriptionChangeHandler,
    setImage,
  ]);
  const hideModal = () => {
    resetname();
    resetLocation();
    resetDescription();
    setSelectedSiteAdmins([]);
    props.hideModal();
  };
  if (props.isShown) {
    return (
      <>
        <Modal
          headerIsShown={true}
          modalHeight="h-[700px]"
          isShown={true} //props.isShown
          hideModal={hideModal}
          modalTitle={editMode ? "Edit Site" : "Create a New Site"}
          modalBottom={
            <div className="flex justify-end mr-4">
              <Button className="mr-3" type="light" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                disabled={!formIsValid}
                isLoading={isSiteSubmitting || isSiteUpdating}
                onClick={submitHandler}
                type="submit"
              >
                {editMode ? "Update" : "Save"}
              </Button>
            </div>
          }
        >
          <p className="text-dark-gray text-sm ">
            {editMode
              ? "Edit this site with few steps"
              : "Create a new site with few steps"}
          </p>
          <form className="space-y-3 mt-5 flex flex-col justify-between h-fit">
            <Input
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
              value={name}
              error={nameIsInValid}
              id="name"
              label="Site name"
              helperText={<>Site name is required</>}
              placeholder="Enter site name"
              required
              name="name"
              type="text"
            />
            <PlaceComponent
              onChange={locationChangeHandler}
              onBlur={locationBlurHandler}
              value={location}
              error={locationIsInValid}
              setCoordinates={setCoordinates}
              id="location"
              label="Location"
              helperText={<>A valid location is required</>}
              placeholder="Site location"
              required
              type="text"
            />
            <Dropdown
              selectHandler={addSiteAdmin}
              id="siteAdmin"
              label="Site admin"
              placeholder="Select a site admin"
              helperText={<>Site admin is required</>}
              loading={users?.loading}
              errorMessage={users?.error?.message}
              items={siteAdmins}
              propertyName="fullName"
            />
            <div className="w-first flex space-x-3">
              {selectedSiteAdmins.length !== 0 &&
                selectedSiteAdmins.map((siteAdmin, index) => (
                  <span
                    key={index}
                    className="bg-primary/10 flex items-center text-primary text-xs rounded-md px-3 py-1"
                  >
                    {siteAdmin.fullName}
                    <button
                      onClick={() => removeSiteAdmin(index)}
                      type="button"
                      className="text-lg ml-2 hover:text-red-500"
                    >
                      <X />
                    </button>
                  </span>
                ))}
            </div>
            <Textarea
              label="Description"
              onChange={descriptionChangeHandler}
              onBlur={descriptionBlurHandler}
              value={description}
              error={descriptionIsInValid}
              id="description"
              placeholder="Add description"
              helperText={<>A valid description is required</>}
              required
            />
            <label
              htmlFor="input-group-1"
              className={`flex justify-between text-start w-full text-sm font-medium text-gray-600 dark:text-white`}
            >
              Upload site image(optional)
              {uploadProgress !== 0 && (
                <Progress className="w-1/2 ml-2" progress={uploadProgress} />
              )}
            </label>
            {!image ? (
              <div className="flex flex-col pb-2 justify-center w-full mb-2">
                <DragAndDrop
                  onDrop={handleImageUpload}
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      JPG, PNG or TIFF (MAX. 10MB)
                    </p>
                  </div>
                  <input
                    accept="image/jpeg, image/jpg, image/png, image/tiff"
                    onChange={(event) => {
                      handleImageUpload(event.target.files);
                    }}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </DragAndDrop>
              </div>
            ) : (
              <div className="flex justify-center items-center space-x-2 mb-2 h-[10rem] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer">
                <div className="group relative">
                  <Image
                    width="w-24"
                    height="h-24"
                    skeletonWidth="w-10"
                    skeletonHeight="h-10"
                    src={image}
                    alt="Project"
                    className="object-cover rounded-md"
                  />
                  <IconButton
                    className="hidden group-hover:flex absolute top-0 bottom-0 left-0 right-0 m-auto border-none bg-white/50 hover:bg-white/70 text-red-500 w-6 h-6"
                    type="rounded"
                    onClick={deleteImage}
                  >
                    <DeleteIcon className="!w-4 !h-4" />
                  </IconButton>
                </div>
              </div>
            )}
          </form>
        </Modal>
      </>
    );
  } else {
    return <></>;
  }
};

export default NewSite;
